<template>
  <div class="inner-layout">

    <div class="page-title">개인정보 제3자 제공동의</div>

    <div class="pageAgreementFullText" v-html="content"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: null
    }
  },
  created() {
    this.setItem();
  },
  methods:{
    async setItem() {
      const { result, data } = await this.$api.getPolicy({ target: 4 })
      if (result === 'success') this.content = data
    },
  },
}
</script>
